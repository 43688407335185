<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>Send Template</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <Form @submit="handleSendTemplate">
                    <div class="setting_wpr">
                        <template-component :contacts="form.contacts.join(',')" v-model="templateIds" ref="template-component" />
                        <div v-show="form.template_id.length">
                            <schedule-component :schedule-data="form" title="template" :close-modal="closeModal" />
                        </div>
                        <div class="action_wpr mt-5">
                            <button :disabled="templateLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="templateLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="templateLoader"></i>{{ templateLoader ? ' Sending' : 'Send' }}</button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Form } from 'vee-validate';
    import { mapState, mapActions } from 'vuex';

    const ScheduleComponent = defineAsyncComponent(() => import('@/components/Schedule'))
    const TemplateComponent = defineAsyncComponent(() => import('@/components/TemplateComponent'))

    export default {
        name: 'Send Template',

        data () {
            return {
                form: {
                    contacts: '',
                    template_id: [],
                    action: '/contacts/send-template',
                    sending_method: 1,
                },
                templateIds: [],
            }
        },

        props: {
            modelValue: Boolean,
            contacts: [Object, Array],
        },

        emits: ['update:modelValue'],

        watch: {
            contacts () {
                const vm = this;

                vm.form.contacts = vm.contacts;
            },

            templateIds (ids) {
                const vm = this;

                vm.form.template_id = ids.join(',');
            },
            modelValue(val) {
                const vm = this;

                if (val == true) {
                    vm.form.contacts = vm.contacts;
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        components: {
            Form,
            ScheduleComponent,
            TemplateComponent
        },

        computed: {
            ...mapState({
                templateLoader: state => state.templateModule.templateLoader,
            }),
        },

        methods: {
            ...mapActions({
                getAllPlaybooks: 'playbookModule/getAllPlaybooks',
            }),

            closeModal () {
                const vm = this;

                vm.resetForm();
                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                              contacts: '',
                              template_id: [],
                              action: '/contacts/send-template',
                              sending_method: 1,
                          };
            },

            handleSendTemplate (params, { setFieldError }) {
                const vm = this;
                const templateComponent = vm.$refs['template-component'];

                templateComponent.handleTemplateSend().then((result) => {
                    if (result) {
                        vm.closeModal();
                    }
                });
            },
        },
    }
</script>
